* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}
/* LanguageSelector.css */
.language-switcher {
  position: relative;
  display: inline-block;
  margin-inline-end: 1rem;
}

.globe-icon {
  cursor: pointer;
  font-size: 24px;
  margin: 1rem;
}

[dir="ltr"] .continue-btn{
  margin: 0;
  margin-left: auto;
}

[dir="rtl"] .continue-btn {
  margin: 0;
  margin-right: auto;
}


[dir="ltr"] .continue-btn::after {
  content: '\2192'; /* Unicode for right arrow */
  margin-inline-start: 0.5rem;
}

[dir="rtl"] .continue-btn::after {
  content: '\2190'; /* Unicode for right arrow */
  margin-inline-start: 0.5rem;
}

[dir="ltr"] .back-btn{
  margin: 0;
  margin-right: auto;
  background-color: #068177;
}

[dir="rtl"] .back-btn {
  margin: 0;
  margin-left: auto;
  background-color: #068177;
}


[dir="ltr"] .back-btn::before {
  content: '\2190'; /* Unicode for left arrow */
  margin-inline-end: 0.5rem;
}

[dir="rtl"] .back-btn::before {
  content: '\2192'; /* Unicode for left arrow */
  margin-inline-end: 0.5rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  /* left: -60px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* padding: 4px 0; */
}

[dir="rtl"] .dropdown-menu {
  left: 0; /* In RTL, float to the right */
}

[dir="ltr"] .dropdown-menu {
  left: -60px; /* In LTR, float to the left */
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  border-radius: 0;
  color: #00CCBB;
}

.dropdown-menu button:hover,
.dropdown-menu button.selected {
  background-color: #00ccbb;
  color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #00ccbb!important;
}
.slick-dots li button:before {
    font-size: 10px!important;
}

button{
  margin: auto;
  padding: 1rem 2rem;
  background-color: #00CCBB;
  border: none;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center ;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
.random{
  height: 30vh;
  width: 100vw;
  background-color: #fff;
  margin-bottom: 360px;
  border-radius: 0 0 40px 40px;
}
.str-btn:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 204, 187, 0.2), 0 6px 20px 0 rgba(0, 204, 187, 0.19);
  transform: translateY(4px);
  transition: transform 0.3s ease;
}
img {
  max-width: 100%;
  height: auto;
}
a{
  text-decoration: none;
}
/* padding classes */
.p-1{
  padding: 1rem;
}
.p-2{
  padding: 2rem;
}
.px-1{
  padding: 0 1rem;
}
.px-2{
  padding: 0 2rem;
}
.py-1{
  margin: 1rem 0;
}
.py-2{
  margin: 2rem 0;
}
/* margin classes */
.m-1{
  margin: 1rem;
}
.m-2{
  margin: 2rem;
}
.mx-1{
  margin: 0 1rem;
}
.mx-2{
  margin: 0 2rem;
}
.my-1{
  margin: 1rem 0;
}
.my-2{
  margin: 2rem 0;
}
.font-light{
  font-weight: 300;
}
.font-medium{
  font-weight: 500;
}
.font-semibold{
  font-weight: 600;
}
.font-bold{
  font-weight: 700;
}
.text-light{
  color: #999999;
}
.App {
  min-height: 100vh;
  max-width: 100%;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #fff;
}

nav {
  position: absolute;
  z-index: 1 ;
  background-color: #fff;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mobile{
  display: none;
}

.green {
  color: #00ccbb !important;
}
.person-card{
  background-color: #EAFCFA;
  border-radius: 1.25rem;
  margin: 2rem 2rem;
}
.person-img{
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.image{
  background-color: #D9D9D9;
  padding: 3rem;
  margin: 2rem;
  border-radius: 1.25rem;
}
.person-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.person-name{
  color: #00564F;
  color: 16px;
}
.person-designation{
  font-size: 12px;
  color: #6EA39F;
}
.person-desc{
  font-size: 14px;
  color: #6EA39F; 
  padding: 0 1.5rem;
}
.person-social-media{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
}
.social-media-btn{
  margin: 0 0.5rem;
  cursor: pointer;
}
.social-media-btn svg{
  height: 1.3rem;
  width: 1.3rem;
  color: #00ccbb;
}
.social-media-icons{
  padding: 0.75rem;
  border-radius: 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

.social-media-icons:hover{
  background-color: rgba(255, 255, 255, 0.2);
  transition: ease-in-out 0.3s;
}
.react-icons {
  vertical-align: middle;
}
.navbar-menu-container {
  display: none;
}

.navbar-links-container a {
  margin-inline-end: 4rem;
  text-decoration: none;
  color: #000;
  font-size: 1.1rem;
  font-weight: 600;
}
.navbar-links-container a:last-child{
  margin-right: 6rem;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.section{
  height: 100vh;
  background-color: #EBFCFA;
}
.glass{
  background: rgba(255, 255, 255, 0.71);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 2rem;
  cursor: pointer;
  margin: auto 2rem;
}

.home-banner-container {
  width: 100vw;
  margin: auto;
  position: relative;
  display: flex;
  padding: 3rem 6rem;
  z-index: 1;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -120px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
  margin-top: 4rem;
}
.home-section-img {
  transform: translate(60%, -50%);
  width: 100%;
  margin: auto;
  z-index: 1;
  padding-top: 50vh;
  right: 50px!important;
}
/* [dir="rtl"] .home-section-img {
  right: 1210px!important;
} */
.home-section-img img{
  width: 40%;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-button-section {
  flex: 1;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.download-container{
  width: 100vw;
  margin: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3rem 6rem;
  z-index: 1;
  background-color: #eafcfa;
  background-image: url("./Assets/contour-line.png");
  background-attachment: fixed;
}

.download-card{
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  /* background-image: linear-gradient(45deg, #bbf7f2, #f4f4f4);
  background-attachment: fixed; */
  /* background-color: #fff; */
  border-radius: 1.25rem;
  margin: 2rem;
}
.download-img{
  position: relative;
}
.layer{
  position: absolute;
  top: 90px;
  left: 25px;
  height: 250px;
}
.iphone{
  position: relative;
  z-index: 2 ;
}
.download-section-left {
  flex: 1;
  /* padding:  20px 65px; */
  padding: 1rem 2rem;
}

.download-section-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;
}

.service-container{
  width: 100vw;
  margin: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3rem 6rem;
  z-index: 1;
  background-color: #fff;
  margin-bottom: 360px;
  border-radius: 0 0 60px 60px;
}
.service-card{
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  background-image: url('./Assets/service-card-bg.png');
  background-attachment: fixed;
  border-radius: 1.25rem;
    }
.card-left {
  flex: 1;
  padding: 20px;
}

.card-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  filter: grayscale(100%);
  padding-inline-end: 4rem;
}
.oval{
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #00CCBB;
  border-radius: 50%;
}
.oval img{
  position: absolute;
  right: -70px !important;
  height: 280px;
  max-width: fit-content;
}
#person1{
  right: -40px!important;
}
.service-card:hover{
  background-color: #e2fdfb;
}
.service-card:hover .card-right{
  filter:none;
}

.service-card{
  background-color: #f4f4f4;
  margin: 2rem;
  padding: 3rem 5rem;
  width: 100%;
}
.card-heading{
  font-size: 24px;
  color: #000;
  font-weight: 500;
  margin: 1rem 0;
}
.card-text{
  font-size: 16px;
  color: #444242;
  margin: 1rem 0;
}
.service-card-btn{
  display: flex;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f4f4f4;
  font-weight: 600;
  padding: 8px 0;
}
.card-arrow{
  background-color: #767676;
  color: #fff;
  padding: 6px 6px 0 6px ;
  margin: auto;
  border-radius: 4px;
  align-items: center;
}
.card-btn-text{
  flex-grow: 1;
}
.card-btn-text p{
  margin: auto 1rem;
}
.card-btn-icon{
  margin: auto 1rem;
  color: #fff;
}
.service-card:hover .service-card-btn{
  background-image: linear-gradient(to left, #00ccbb, #e2fdfb);
}
.service-card:hover .card-arrow{
  background-color: #00CCBB;
}

.service-card-btn:hover{
  transform: rotateX('30');
}

.about-card{
  background-color: #fffefe;
  margin: 1rem auto;
  padding: 3rem;
  width: 30%;
}

.about-icon{
  position: absolute;
}
.about-card-heading{  
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-inline-start: 4rem;
}
.about-card-text{
  margin-inline-start: 4rem;
  margin-top: 1.5rem;
  font-size: 14px;
  color: #444242;
}

.contact-wrapper{
  background-image: url("./Assets/map-bg-contact.png");
  background-size: cover;
  background-color: #fff;
  border-radius: 0 0 60px 60px;
  margin-bottom: 360px;
}

/* input[type="text"],
input[type="email"],
input[type="tel"],
textarea , Select{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 1.25rem;
    border: none;
    outline: none;
    background-color: #f4f6f7;
} */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

#c_code{
  width: 25%;

}
#t_no{
  width: 75%;
}

.footer-bottom{
  display: flex ;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 0 4rem;
}
.footer-left{
  display: flex;
  gap: 1rem;
}

.line{
  height: 1px;
  background-color: #fff;
  margin: 1rem 6rem;
}

.faq{
  background-color: #fff;
  border-radius: 0 0 60px 60px;
}

.faq-image{
  position: relative;
  height: 60vh;
  background-image: url('./Assets/comingsoon-bg.png');
  background-attachment: fixed;
  background-size: inherit;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.faq-section-one{
  position: relative;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 6rem;
  color: #fff;
}

.faq-container{
  transform: translateY(-80px);
}
.faq-card{
  height: auto;
  margin: 0 6rem;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 1.25rem;
  box-shadow: 0 4px 6px 0 rgba(91, 126, 124, 0.1), 0 6px 20px 0 rgba(85, 113, 110, 0.1);
}
.faq-card p{
  margin-bottom: 1rem;
  text-align: justify;
}
.faq-card:last-child{
  margin-bottom: 360px;
}
.contact-card{
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 1.25rem;
  box-shadow: 0 4px 6px 0 rgba(91, 126, 124, 0.1), 0 6px 20px 0 rgba(85, 113, 110, 0.1);
  transform: translateY(-20%);
}
.contact-card-heading h1{
  font-size: 40px;
  font-weight: 600;
}
.contact-card-heading p{
  font-size: 14px;
  color: #7c8084;
}
.store-button{
  margin: 20px 0;
  border-radius: 10px;
  cursor:pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 204, 187, 0.2), 0 6px 20px 0 rgba(0, 204, 187, 0.19);
}
.store-button:hover{
  transform: translateY(-10px);
  transition: transform 0.3s ease;
}

.arounder-registration-card{
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 2rem;
  /* background-image: url("./Assets/form_reg_bg.png");
  background-size: cover;
  background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  border-radius: 1.25rem;
  box-shadow: 0 4px 6px 0 rgba(91, 126, 124, 0.1), 0 6px 20px 0 rgba(85, 113, 110, 0.1);
  transform: translateY(-20%);
  /* background: rgb(154,255,247); */
  /* background: linear-gradient(180deg, rgba(154,255,247,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); */
  background: linear-gradient(#ACFFF9 0%, #FFFFFF 25%, #FFFFFF 100%);

}

/* .store-btn-apple{
  margin-inline-start: 2rem !important;
} */
.primary-heading {
  font-size: clamp(1.5rem, 5vw, 3.3rem);
  font-weight: 500;
  color: #262626;
  line-height: 3.5rem;
  max-width: 600px;
  margin-top: 4rem;
}
.primary-heading-white {
  font-size: clamp(1.5rem, 5vw, 3.3rem);
  color: #fff;
  line-height: 3.5rem;
  max-width: 600px;
  margin-top: 5rem;
}
.primary-heading-2 {
  font-size: clamp(1rem, 3vw, 3rem);
  color: #4c4c4c;
  font-weight: 600;
  line-height: 3.5rem;
  max-width: 600px;
  margin: 5rem 5rem 0 5rem;
  justify-content: center;
  text-align: center;
}
.primary-text {
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.primary-text-white {
  max-width: 500px;
  color: #fff;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #00CCBB;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-inline-start: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #15a99d;
}
.about-container {
  display: flex;
  padding-top: 20vh;
  padding-bottom: 10vh;
  align-items: center;
  padding-left: 6rem;
  padding-inline-end: 6rem;
  background-color: #fff;
}
.about-text{
  flex: 1;
  padding: 2rem;
}
.about-heading{
  font-size: 50px;
  margin: 1rem 0;
}
.about-img{
  flex: 1;
  display: flex;
  justify-content: center;
}
.about-img img{
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
}
.about-team{
  background-color: #c0dbd8;
  background-image: url("./Assets/bg.svg");
  background-attachment: fixed;
  padding: 2rem 3rem;
}
.about-background-image-container {
  position: relative;
  left: -150px;
  z-index: -2;
}
.partner-section{
  background-color: #fff;
  padding: 4rem 6rem;
}
.partner-heading{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  margin: 1rem;
}
.partner-tagline{
  margin: auto;
  padding: 0 10rem;
  font-size: 12px;
  text-align: center;
}
.partner-slider-section{
  margin: 4rem 2rem;
}
.partner-logo{
  margin: auto;
  padding: 0 1rem;
  filter: grayscale();
  cursor: pointer;
}
.partner-logo:hover{
  filter: none;
}
.slick-arrow{
  display: none;
}
.join-container{
  display: flex;
  align-items: center;
  padding: 3rem 6rem;
  background-color: #fff;
  margin-bottom: 360px;
  border-radius: 0 0 60px 60px;
  background-color: #EAFCFA;
}
.join-img{
  flex: 1;
}
.join-text{
  flex: 1;
  align-items: flex-end;
}
.text-heading{
  color: #00564F;
  margin: 1rem 0;
  text-align: end;
}
.join-desc{
  text-align: justify;
}
.join-btn{
  width: 50%;
  padding: 1rem 2rem;
  background-color: #00CCBB;
  border: none;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  float: right;
}
.order-banner{
  position: absolute;
  left: 0;
  bottom: -900px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #00CCBB;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-inline-start: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  border-radius: 0 0 60px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
  margin-bottom: 360px;
  background-color: #fff;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.7rem 1rem;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.footer{
  background-image: linear-gradient(30deg, #137f75, #00a386);
  position: fixed;
  bottom: 0;
  z-index: -10;
  height: 400px;
  width: 100vw;
}
hr{
  margin: 0 4rem;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4rem 4rem 1rem 4rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-logo{
  width: 100px;
  height: auto;
}
.footer-column {
  flex: 1;
  margin-right: 4rem; /* Adjust spacing between columns */
  color: #fff;
}
.footer-column h3{
  color: #fff;
  margin-bottom: 1rem;
}

.footer-column:last-child {
  margin-right: 0; /* Remove margin from last column */
}
.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px; /* Adjust spacing between links */
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.underline-hover{
  position: relative;
  display: inline-block;
}

.underline-hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #00CCBB;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.underline-hover:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline-hover:hover::after,
.underline-hover.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.active{
  color: #00CCBB !important;
}

.footer-icons {
  margin-top: 2.5rem;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
  color: #fff;
}
.testimonial-section-bottom {
  margin: auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #00CCBB;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.community-section-wrapper{
  margin: 0 8rem auto !important;
  background: #fff;
}
.work-section-wrapper {
  margin-top: 3rem;
  background: #EBFCFA;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  /* background-color: white; */
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #fff;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.order-section-bottom{
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  justify-content: center;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.nav-logo-container {
  display: flex;
  justify-content: flex-start;
  margin: 0 6rem;
}

.arounder-carousel-card{
  margin-top: 1rem;
  display: flex!important;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.arounder-carousel-card > p{
  font-size: 14px;
  color: #999999;
  margin: 1rem 0;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .primary-heading{
    font-size: clamp(1.5rem, 4vw, 3.3rem);
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  .home-banner-container{
    width: 45vw;
    padding: 2rem 0;
    margin: auto 4rem;
  }
  .home-section-img img{
    margin: 0;
    width: 45%;
  }
  .home-section-img{
    transform: translate(60%, -50%);

  }
  /* .footer-left img{
    width: 35%;
  } */
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
    margin: 0 2rem;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0 6rem;
    width: auto;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-section-img{
    padding-top: 30vh;
    transform: translate(40%, -50%);
    right: 150px!important;
    z-index: 2!important;
    background-color: #fff;
  }
  .home-section-img img{
    width: 65%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 16rem;
  }
  .download-container{
    z-index: 2;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-inline-start: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}

.light-text{
  margin: 0 !important;
  font-size: 1rem !important;
  color: #ebe5e5 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.hr-line{
margin: 0 6rem;
}
.work-section-1{
  display: flex;
  justify-content: space-around;
  margin: 2rem 8rem;
}

.work-section-2{
  display: flex;
  justify-content: space-around;
  margin: 2rem 8rem;
}

.order-text{
  padding: 1rem 0;
  font-size: 18px;
}

.card-1{
  width: 350px;
  height:170px;
  background-color: #FAFAFA;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-1 p{
  padding: 2rem;
}

.card-img{
  position: absolute;
  left: 260px;
  bottom: 20px;
}

.arrow{
  cursor: pointer;
  margin: 1rem;
}
.coming-section-container{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url(./Assets/map-bg-home.png);
  background-size: cover;
  position: relative;
}

.coming-section-top{
  width: 100%;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%,-50%);
  text-align: center;
}
.coming-section-top p{
  font-size: 1.2rem;
  margin: 0 350px;
}

.coming-soon-heading{
  font-size: 7em;
  margin-bottom: 20px;
  font-weight: 600;
  color: #fff;
}
.coming-soon-bg-img-ph{
  display: none;
}


@keyframes zoom {
  0%{
    width: 70%;
  }
  50%{
    width: 60%;
  }
  100%{
    width: 70%;
  }
}

.coming-section-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.coming-section-bottom a {
  margin: 0 1rem;
  color: #fff;
}
.subscribe-email-link {
  border-radius: 20px;
  padding: 10px 15px;
  background-color: rgba(0, 86, 79, 1);
  display: flex;
  justify-items: flex-end;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.subscribe-email-link:hover{
  background-color: rgb(1, 68, 63);
}

@media only screen and (max-width: 650px) {
  .coming-section-top{
    top: 50%;
  }
  .coming-soon-heading{
    font-size: 4em;
  }
  .coming-section-bottom{
    justify-content: space-around;
}
  .coming-section-bottom a {
    margin: 0 10px;
  }
  .coming-soon-bg-img-ph{
    display: block;
  }
  .coming-soon-bg-img{
    display: none;
  }
  .coming-section-top p{
    font-size: 1rem;
    margin: 0 40px;
  }
  .store-button{
    width: 40vw;
  }
  .home-section-img {
    right: 50%!important;
    transform: translate(50%, -50%);
    width: 100%;
    margin: auto;
    z-index: 2!important;
    background-color: #fff;
    padding-top: 20vh;
    padding-left: 1rem;
    padding-inline-end: 1rem;
  }
  .home-text-section, .about-section-text-container{
    margin-top: 4rem;
  }
  .home-section-img img{
    margin: 0;
    width: 100%;
  }
  .home-banner-container{
    padding: 0;
    margin: 0;
  }
  .hero-title{
    margin-top: 14rem;
    font-weight: 600;
    font-size: 30px;
  }
  .download-container{
    padding: 0;
    z-index: 2;
  }
  .download-section-right {
    padding: 0 2rem;
  }
  .download-card{
    flex-direction: column;
    margin: 4rem 2rem;
    padding-bottom: 2rem;
  }
  .download-section-right{
    text-align: center;
  }
  .mobile{
    display: block;
  }
  .web{
    display: none;
  }
  .footer-button-section{
    display: flex;
  }
  .download-button img{
    margin: 1rem auto;
    width: 90%;
  }
  .service-container{
    padding: 0;
    border-radius: 0 0 40px 40px;
    margin-bottom: 500px;
  }
  .contact-wrapper{
    border-radius: 0 0 40px 40px;
    margin-bottom: 500px;
  }
  .faq{
    border-radius: 0 0 40px 40px;
  }
  .faq-section-one{
    margin: 0 2rem;
  }
  .faq-card:last-child{
    margin-bottom: 500px;
  }
  .service-card{
    padding: 0 1rem;
    flex-direction: column;
  }
  .card-btn-text p {
    margin: auto 0.5rem;
    text-align: center;
  }
  .card-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center  ;
  }
  .card-right{
    margin-top: 4rem;
    justify-content: center;
  }
  .oval{
    top: -8%;
  }
  .oval img{
    right: 70%!important;
    transform: translateX(50%)!important;
    top: -20%!important ;
  }
  #person1  {
    right: 25%!important;
  }
  .faq-card{
    margin: 1rem 2rem;
  }
  .contact-card{
    width: 80%;
  }
  .arounder-registration-card{
    width: 80%;
  }
  .footer{
    height: 550px;
    padding: 0;
  }
  .footer-logo {
    width: 80px;
    margin: auto;
  }
  .footer-top{
    flex-wrap: wrap;
    padding: 0;
    padding-top: 4rem;
  }
  .tagline{
    margin: 0.5rem auto;
    padding: 1rem;
    text-align: center;
  }
  .footer-column:nth-child(1) {
    display: flex;
    flex-direction: column;
    order: 0;
    flex: 0 0 100%;
  }
  .footer-column{
    margin-right: 0;
  }
  .footer-column h3 {
    display: flex;
    justify-content: center;
  }
  .footer-icons{
    margin: 1rem auto;
  }
  .footer-column ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-bottom {
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
  hr{
    margin: 0;
  }
  .footer-left{
    display: flex;
    order: 0;
    flex: 0 0 100%;
    justify-content: center;
    gap: 1rem;
  }
  .footer-right{
    margin: auto!important;
  }
  .about-container {
    display: flex;
    flex-direction: column; /* Stack items vertically on small screens */
    padding-left: 1rem;
    padding-inline-end: 1rem;
    padding-top: 10vh;
    margin: 0;
  }
  .random{
    height: 20vh;
    margin-bottom: 500px;
    border-radius: 0 0 40px 40px;
  }
  .about-heading{
    display: flex;
    justify-content: center;
    font-size: 40px;
  }

  .about-desc{
    margin: 0;
  }
  
  .about-text,
  .about-img {
    width: 100%; /* Take up full width on small screens */
  }
  
  .about-img {
    margin-top: 1rem; /* Add some space between text and image */
  }
  .partner-section{
    padding: 1rem;
  } 
  .partner-tagline{
    padding: 2rem;
  }
  .partner-heading{
    margin: 0;
    margin-top: 2rem;
    font-size: 28px;
  }
  .join-container{
    margin-bottom: 500px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  .join-img{
    padding: 2rem 4rem;
  }
  .text-heading{
    text-align: center;
  }
  .join-desc{
    text-align: center;
  }
  .join-btn{
    margin: 2rem auto;
    float: none;
  }

}

@media only screen and (min-width: 650px) {

  .coming-soon-heading{
    font-size: 5.5em;
  }
  .coming-soon-bg-img{
    padding: 20px;
  }
  .coming-section-top p{
    font-size: 1rem;
    margin: 0 150px;
  }
    [dir="rtl"] .home-section-img {
  right: 400px!important;
}
}

@media only screen and (min-width: 750px) and (max-width: 1200px) {
  .arounder-registration-card {
    width: 60%;
  }
}

@media only screen and (min-width: 650px) and (max-width: 1000px) {
  .download-section-right {
  padding: 0 2rem !important;
}
}

@media only screen and (min-width: 800px) {

  .coming-soon-heading{
    font-size: 6em;
  }
  .coming-soon-bg-img{
    padding: 20px;
  }
  .coming-section-top p{
    font-size: 1.2rem;
    margin: 0 200px;
  }
  [dir="rtl"] .home-section-img {
  right: 920px!important;
}
[dir="rtl"] .home-section-img {
    transform: translate(55%, -50%);
}
}

@media only screen and (min-width: 1000px) {

  .coming-soon-heading{
    font-size: 6.5em;
  }
  .coming-soon-bg-img{
    padding: 20px;
  }
  .coming-section-top p{
    font-size: 1.2rem;
    margin: 0 300px;
  }
  [dir="rtl"] .home-section-img {
  right: 1100px!important;
}
[dir='rtl'] .primary-text{
  max-width: 400px;
}
}
@media only screen and (min-width: 1200px) {

  .coming-soon-heading{
    font-size: 8em;
  }
  .coming-soon-bg-img{
    padding: 20px;
  }
  .coming-section-top p{
    font-size: 1.2rem;
    margin: 0 450px;
  }
  [dir="rtl"] .home-section-img {
  right: 1500px!important;
  }
}

@media screen and (min-width: 1500px) {
  .primary-heading{
    font-size: clamp(1.5rem,5vw,5.3rem);
    max-width: 1000px;
    line-height: 5.5rem;
  }
  .primary-text {
    max-width: 800px;
    font-size: 24px;
}
.about-heading {
  font-size: 70px;
}
.about-desc{
  font-size: 24px;
}
.about-img img {
  padding: 2rem;
  height: 500px;
}
.faq-section-one{
  font-size: 30px;
}
.footer-column{
  font-size: 20px;
}
.faq-card:last-child {
  margin-bottom: 340px;
}
.footer {
  height: 380px;
}
.footer-right{
  font-size: 20px;
}
.footer-left{
  display: flex;
  gap: 1rem;
}
.faq-card{
  font-size: 25px;
}
.contact-wrapper{
  margin-bottom: 340px; 
}
.service-container{
  margin-bottom: 340px;
}
.card-heading{
  font-size: 70px!important;
}
.card-text{
  font-size: 30px;
}
.download-card{
  width: 80%;
}
.download-section-right{
  padding-left: 0;
}
.download-section-left{
  padding-inline-end: 0;
}
/* .download-button img{
  width: 30%;
} */
 .download-button {
  justify-content: flex-start!important;
 }
[dir="rtl"] .home-section-img {
  right: 2000px!important;
}
}
